.isi-container {
  position: fixed; /* Change to absolute for initial non-fixed state */
  left: 0;
  width: 100%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  z-index: 999;
  box-shadow: 0 8px 10px 3px rgba(0, 0, 0, 0.5);
  text-align: left;
}

.isi-container.normal {
  top: 75%;
}

.isi-container.fixed {
  top: calc(
    (var(--vh, 1vh) * 100) - 82px
  ); /* Keep at the bottom of the viewport */
}

.isi-container.open {
  top: unset !important;
  bottom: 0 !important;
}

.isi-container.relative {
  position: relative;
  margin-top: -24px;
}

.isi-container.relative .isi-toggle {
  display: none;
}

.isi-content {
  padding: 2% 4% 4% 4%;
  box-shadow: 0 4px 16px -4px rgba(0, 26, 51, 0.16);
  background-color: #ffffff;
  transition: max-height 0.5s ease-in-out;
}

.isi-content.open {
  overflow: auto !important;
  max-height: 75vh !important;
}

.isi-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddedec;
  padding: 15px;
  font-family: "Poppins", Sans-serif;
  font-size: 15px;
  color: #346059;
  font-weight: 400;
  line-height: normal;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0 auto;
  cursor: pointer;
  min-width: 128px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 100%;
}
